import { Link, navigate } from 'gatsby';
import firebase from 'gatsby-plugin-firebase';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Dropdown, Input } from 'semantic-ui-react';
import Header from '../../components/Header';

import Layout from '../../components/layout';
import { useUserState } from '../../context/UserContext';

const FIELDS = {
  policyNumber: 'policyNumber',
  amount: 'amount',
  date: 'date',
};

const Form = ({ memberOptions }) => {
  const [state, setState] = useState<'idle' | 'pending'>('idle');
  const { register, handleSubmit, getValues, setValue, trigger, errors } = useForm();


  useEffect(() => {
    Object.keys(FIELDS).forEach((key) => register({ name: key }, { required: true }));
  }, []);

  const onChange = useCallback(async (_event, { name, value }) => {
    setValue(name, value);
    await trigger(name);
  }, [setValue, trigger]);

  const onSubmit = useCallback(async () => {
    setState('pending')
    const payload = getValues();
    payload[FIELDS.date] = new Date(payload[FIELDS.date]).getTime();
    await firebase.firestore().collection('contributions').add(payload);
    setState('idle');
  }, [getValues]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Dropdown
        error={Boolean(errors[FIELDS.policyNumber])}
        placeholder='Select a member no.'
        fluid
        search
        selection
        onChange={onChange}
        name={FIELDS.policyNumber}
        options={memberOptions}
        required
      />
      <Input
        error={Boolean(errors[FIELDS.amount])}
        fluid
        label="Contribution Amount"
        name={FIELDS.amount}
        onChange={onChange}
        placeholder="R200"
        min={0}
        type="number"
        required
      />
      <Input
        error={Boolean(errors[FIELDS.date])}
        fluid
        label="Date"
        name={FIELDS.date}
        onChange={onChange}
        type="date"
        required
      />
      <Button
        type="submit"
        loadin={state === 'pending'}
        disabled={Object.keys(errors).length > 0 || state === 'pending'}
      >
        Add
      </Button>
    </form>
  );
};

const Contributions = () => {
  const { user } = useUserState();
  const [registrations, setRegistrations] = useState([]);

  useEffect(() => {
    firebase.firestore().collection('registrations').get().then((querySnapshot) => {
      setRegistrations(querySnapshot.docs.map((doc) => doc.data()));
    });
  }, []);

  const memberOptions = registrations.map(({ policyNumber, firstNames, surname }) => ({
    key: `${policyNumber}${firstNames}${surname}`,
    value: policyNumber,
    text: `${surname} ${firstNames} (${policyNumber})`
  }));


  if (!user) {
    return (
      <Layout title="Admin">
        <Header title="Contributions" subtitle="Unauthorized access" />
        <div id="main">
          <section id="content" className="main">
            <p>You are not authorized to view this page, please <Link to="/sign-in">Sign In</Link> to continue.</p>
          </section>
        </div>
      </Layout>
    );
  }

  return (
    <Layout title="Admin">
      <Header title="Contributions" subtitle="Manage member contributions" />
      <div id="main" className="contributions">
        <section id="content" className="main">
          <h3>Add New Contribution</h3>
          <Form memberOptions={memberOptions} />
          <hr />

          <h3>Existing Member Contributions</h3>
        </section>
      </div>
    </Layout>
  );
};

export default Contributions;
